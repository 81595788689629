<template>
	<div class="wzitem_part">
		<div class="wzitem_sub align_items_c">
			<div
				class="wzitem_sub_Right"
				:class="{
					wzitem_sub_Right_show: item.pic != null && item.pic.length > 0,
				}"
			>
				<img
					class="wzitem_sub_img pointer"
					v-lazy="item.pic"
					:key="item.pic"
					alt=""
				/>
			</div>
			<div
				class="wzitem_sub_Left"
				:class="{ reItem_Left_full: item.pic != null && item.pic.length > 0 }"
			>
				<router-link
					target="_blank"
					:to="{ name: '/gw_detail', params: { id: item.id } }"
				>
					<div class="wzitem_sub_Left_title hovercolor pointer">
						{{
							localLanguage == 2
								? item.title
									? item.title
									: item.title
								: item.title
						}}
					</div>
				</router-link>
				<div
					class="wzitem_sub_Left_content hovercolor pointer"
					:class="{ ellipsis_one: !contentflag }"
				>
					{{
						localLanguage == 2
							? item.illustrate_en
								? item.illustrate_en
								: item.illustrate
							: item.illustrate
					}}
				</div>
				<div
					v-if="!contentflag"
					style="display: flex; height: 15px; margin-top: 10px"
				>
					<img
						class="tagFlag"
						v-if="item.is_top"
						src="https://res.metaera.hk/resources/assets/images/home/set_top.png"
					/>
					<div class="wzitem_sub_Left_author"> {{ item.author.nickname }}</div>
					<!-- <div class="wzitem_sub_Left_line"></div> -->
					<div class="wzitem_sub_Left_time">{{ item.release_time }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { newBus } from "@/components/pc/topHeader2.vue";
export default {
	props: ["item", "contentflag"],
	data() {
		return {
			allData: [],
			localLanguage: 1,
		};
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			// 处理收到的通知，并更新组件状态
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {},
};
</script>

<style scoped>
.wzitem_sub {
	display: flex;
	margin-top: 30px;
	/* min-height: 140px; */
}
.wzitem_sub_Left {
	width: 100%;
}
.reItem_Left_full {
	width: clamp(90px, calc(100vw * 570 / 1440), 570px);
}

.wzitem_sub_Left_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(12px, calc(100vw * 18 / 1440), 18px);
	line-height: 1.2;
	font-weight: bold;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #fff;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.wzitem_sub_Left_content {
	margin-top: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 14 / 1440), 14px);
	font-weight: normal;
	line-height: 1.5;
	letter-spacing: 0em;

	/* 正文色/正文辅助色 */
	color: #e1e3f1;
	opacity: 0.7;
}
.tagFlag {
	width: 17px;
	height: 10px;
	margin-right: 5px;
}
.wzitem_sub_Left_time,
.wzitem_sub_Left_author {
	margin-right: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 1;
	letter-spacing: 0em;
	color: #fff;
}
.wzitem_sub_Left_time {
	color: #b2b2b2;
	margin-top: 0;
}
.wzitem_sub_Right {
	display: none;
}

.wzitem_sub_Right_show {
	display: block;
	/* margin-left: 5px; */
	/* margin-right: 20px; */
}
.wzitem_part .wzitem_sub .wzitem_sub_img {
	width: clamp(90px, calc(100vw * 160 / 1440), 160px);
	height: clamp(50px, calc(100vw * 90 / 1440), 90px);
	margin-right: clamp(10px, calc(100vw * 3 / 144), 30px);
	border-radius: 6px;
	object-fit: cover;
}

.wzitem_sub_Left_line {
	margin-left: 20px;
	margin-right: 7px;
	bottom: 0px;
	width: 2px;
	height: 12px;
	border-radius: 24px;
	opacity: 1;
	background: #b6b9cc;
}
@media screen and (max-width: 900px) {
	.reItem_Left_full,
	.wzitem_sub_Left_content {
		flex-grow: 1;
	}
	.wzitem_sub_Left_time {
		margin-right: 0;
	}
	.wzitem_sub_Left_author {
		margin-right: 10px;
	}
}
</style>
